import React, { Component } from "react"

import $ from "jquery"
import Cookies from "js-cookie"

import FormField from "../components/utilities/FormField"

import "./servicesForm.scss"

class ServicesForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      contactCompany: "",
    }

    this.submit = this.submit.bind(this)
    this.nameChange = this.nameChange.bind(this)
    this.emailChange = this.emailChange.bind(this)
    this.phoneChange = this.phoneChange.bind(this)
    this.companyChange = this.companyChange.bind(this)
  }

  submit(e) {
    e.preventDefault()
    let formObject = $("#heroForm")
    let postDt = new Date()
    let loadDt = new Date()
    //let _sid = 'qq3eu25egawyabolsjpyzcr5';
    let _sid = Cookies.get("ASP.NET_SessionId")
    if (formObject.find('input[name="js"]').length === 0) {
      let str = '<input type="hidden" name="js" value="1" />'
      formObject.append(str)
    }
    if (formObject.find('input[name="ttp"]').length === 0) {
      let str =
        '<input type="hidden" name="ttp" value="' +
        ((postDt - loadDt) / 1000).toString() +
        '" />'
      formObject.append(str)
    }
    if (formObject.find('input[name="sid"]').length === 0) {
      let str = '<input type="hidden" name="sid" value="' + _sid + '" />'
      formObject.append(str)
    } else {
      formObject.find('input[name="sid"]').val(_sid)
    }

    let post_data = formObject.serialize()

    // console.log(post_data);

    this.setState({
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      contactCompany: "",
    })

    $.ajax({
      url: "https://metrix.meritmile.com/email.aspx?ajax=1",
      type: "POST",
      data: post_data,
      cache: false,
      dataType: "json",
      success: function(data) {
        // Success..
        // let r = data[0];
        // let redirect_url = "https://www.meritmile.com/uploads/2019/01/Merit-Mile-Industry-Pulse-2019-Outdoor-Retail-Industry-Trends.pdf";

        // formObject.find('.feedback').show().removeClass('alert-error').addClass('alert-success').html(r.Message).css('display', 'block').focus();
        // window.location.href = redirect_url;

        $("#heroForm").hide()
        $(".form-wrapper .thankyou").show()
        // window.open(
        //   "https://mm-stats.com/xibfc",
        //   "_blank" // <- This is what makes it open in a new tab.
        // )
      },
      // Fail..
      error: function(result) {
        let r = result[0]

        formObject
          .find(".feedback")
          .show()
          .addClass("alert-error")
          .html(r.Message)
          .css("display", "block")
          .focus()
        formObject
          .find(".submit")
          .attr("disabled", false)
          .removeClass("disabled")
      },
    })
  }

  nameChange(e) {
    this.setState({ contactName: e.target.value })
  }

  emailChange(e) {
    this.setState({ contactEmail: e.target.value })
  }

  phoneChange(e) {
    this.setState({ contactPhone: e.target.value })
  }

  companyChange(e) {
    this.setState({ contactCompany: e.target.value })
  }

  render() {
    return (
      <div className="form-wrapper">
        <form
          id="heroForm"
          name="metrixform"
          method="POST"
          action="https://metrix.meritmile.com/email.aspx"
          onSubmit={this.submit}
        >
          <FormField
            fieldID={"namehero"}
            valChange={this.nameChange}
            val={this.state.contactName}
            type={"text"}
            name={"name"}
            label={"Full Name"}
          />
          <FormField
            fieldID={"emailhero"}
            valChange={this.emailChange}
            val={this.state.contactEmail}
            type={"email"}
            name={"email"}
            label={"Email"}
          />
          <FormField
            fieldID={"companyhero"}
            valChange={this.companyChange}
            val={this.state.contactCompany}
            type={"text"}
            name={"company"}
            label={"Company"}
          />
          <FormField
            fieldID={"telephonehero"}
            valChange={this.phoneChange}
            val={this.state.contactPhone}
            type={"tel"}
            name={"telephone"}
            label={"Telephone"}
          />
          <button type="submit" className="btn btn-white">
            SUBMIT
          </button>
          <div className="form-group">
            <input
              type="hidden"
              name="referrer"
              value={`https://www.meritmile.com/services/`}
            />
            <input
              type="hidden"
              name="auth"
              value="c857b4c8-ff8d-4b77-834f-fb9380130405"
            />
            <input
              type="text"
              className="d-none hu"
              id="human-check"
              name="human-check"
            />
            <div className="feedback"></div>
            <div className="fix"></div>
          </div>
      </form>
      <div className="thankyou">
        <h4>Thank You</h4>
        <p>
          Your submission has been sent!
        </p>
        <p>
          Add info@meritmile.com to your contacts to ensure future
          communications will not be sent to spam.
        </p>
      </div>
    </div>
    )
  }
}

export default ServicesForm;